/* This example requires Tailwind CSS v2.0+ */
import { useMemo } from "react"
import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import { Link, Outlet, useMatch, useLocation, useNavigate } from "react-router-dom"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const getTabs = (pathname) => [
  {
    name: "Hotels & Lodging",
    href: "/accommodations",
    current: pathname === "/accommodations",
  },
  {
    name: "Travel",
    href: "/accommodations/travel",
    current: pathname === "/accommodations/travel",
  },
]

function Nav() {
  const navigate = useNavigate()
  const location = useLocation()
  const tabs = useMemo(() => getTabs(location.pathname), [location.pathname])

  return (
    <div className="grid grid-cols-5">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-green focus:border-green border-green rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={(e) => {
            navigate(tabs.find((t) => t.name === e.target.value).href)
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block col-span-3 col-start-2">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex" aria-label="Tabs">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.current
                    ? "border-green-dark text-green-dark"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "w-1/4 py-4 px-1 text-center border-b-2 font-bold text-2xl font-dance"
                )}
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

function Accommodations() {
  return (
    <div>
      <Outlet />
    </div>
  )
}

export default Accommodations
