const QouteEntry = ({ text, author, profession }) => {
  return (
    <section className="overflow-hidden py-12">
      <div className="relative mx-auto max-w-7xl px-6 lg:px-8">
        <div className="relative">
          <blockquote className="mt-10">
            <div className="mx-auto max-w-3xl text-center text-2xl font-medium leading-9 text-gray-900">
              <p>{`"${text}"`}</p>
            </div>
            <footer className="mt-8">
              <div className="md:flex md:items-center md:justify-center">
                <div className="mt-3 text-center md:mt-0 md:ml-4 md:flex md:items-center">
                  <div className="text-base font-medium text-gray-900">{author}</div>

                  <svg className="mx-1 hidden h-5 w-5 text-green-dark md:block" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M11 0h3L9 20H6l5-20z" />
                  </svg>

                  <div className="text-base font-medium text-gray-500">{profession}</div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </section>
  )
}

export default function Movie() {
  return (
    <div className="bg-green-light overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mt-4 grid lg:grid-cols-5 gap-1">
          <div className="col-span-3 col-start-2 font-dance text-3xl">Where it all began...</div>

          <div className="mt-2 col-span-3 col-start-2">
            <div style={{ width: "100%", height: "20rem" }}>
              <iframe
                src="https://player.vimeo.com/video/782284924?h=545952a26d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                title="You have bewitched me"
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            </div>
          </div>

          <div className="col-span-3 col-start-2 font-dance text-3xl">
            <QouteEntry
              text={"To be fond of dancing was a certain step towards falling in love."}
              author="Jane Austen"
              profession={"Pride & Prejudice"}
            />
          </div>

          <div className="col-span-3 col-start-2 font-dance text-3xl">
            <QouteEntry
              text={"You must allow me to tell you how ardently I admire and love you."}
              author="Jane Austen"
              profession={"Pride & Prejudice"}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
