import { ClockIcon } from "@heroicons/react/solid"

const Divide = ({ label }) => (
  <div className="relative mt-4 mb-4">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-green-dark" />
    </div>
    <div className="relative flex justify-center">
      <span className="bg-green rounded-md px-3 text-base font-sans leading-6 text-gray-900">{label}</span>
    </div>
  </div>
)

const DayItem = ({ time, label, description }) => (
  <div className="mb-2">
    <div className="flex flex-1 justify-center font-sans italic">
      <ClockIcon className="h-5 w-5 text-green-dark" /> <span>{time}</span>
    </div>
    <div className="">{label}</div>
    {description && <div className="text-gray-700">{description}</div>}
  </div>
)

const Schedule = () => {
  return (
    <div className="bg-green-light overflow-hidden">
      <div className="max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8 md:max-w-screen-md grid grid-cols-1 content-center text-center">
        <div className="mb-2">
          <span className="font-dance text-4xl">Wedding Schedule</span>
        </div>

        <Divide label={"Friday, June 9th"} />
        <DayItem time={"All-Day"} label={"Guest Arrival"} />

        <Divide label={"Saturday, June 10th"} />
        <DayItem
          time={"4:30 PM"}
          label={"Wedding Ceremony Begins"}
          description="Please account for driving, parking, and walking time. Those with a baby or toddler are asked to drop them off with our childcare professional in the parlor until the reception begins."
        />
        <DayItem time={"5:00 PM"} label={"Reception Begins"} description="Cocktail hour, dinner, and dancing" />
        <DayItem time={"11:00 PM"} label={"Reception Ends"} description="Dancing concludes and open bar closes" />
        <DayItem
          time={"11:00 PM"}
          label={"After-Party"}
          description="Anyone wishing to keep the party going can head across the street to Fargo Bar & Grill"
        />

        <Divide label={"Sunday, June 11th"} />
        <DayItem
          time={"9:00 AM"}
          label={"Grab-and-Go Breakfast (All are welcome)"}
          description="All guests are welcome to Taylor House (across the street from the main Inn building) for a casual, grab-and-go breakfast"
        />
        <DayItem
          time={"11:00 AM"}
          label={"Inns of Aurora Check-Out"}
          description="For our guests staying on the Inns of Aurora property, check-out is sharply at 11am. Have a safe trip home!"
        />
      </div>
    </div>
  )
}

export default Schedule
