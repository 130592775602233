/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          rose: colors.rose,
        },
      },
    },
  }
  ```
*/
import { Fragment } from "react"
import { ChatAltIcon, TagIcon, UserCircleIcon } from "@heroicons/react/solid"
import dayjs from "dayjs"

const activity = [
  {
    id: 1,
    type: "comment",
    person: { name: "Rich", href: "#" },
    imageUrl: "couple/rich-headshot.png",
    comment: "Anna liked Rich on Hinge during the Pandemic",
    date: "4/24/20",
  },
  {
    id: 2,
    type: "assignment",
    person: { name: "Rich", href: "#" },
    assigned: { name: "Anna", href: "#" },
    verb: "matched with",
    date: "4/26/20",
  },
  {
    id: 3,
    type: "tags",
    person: { name: "Anna", href: "#" },
    tags: [{ name: "Ben Affleck", href: "#", color: "bg-green" }],
    verb: "laughed at Rich's love of",
    date: "4/26/20",
  },
  {
    id: 4,
    type: "comment",
    person: { name: "Anna", href: "#" },
    imageUrl: "couple/anna-headshot.jpg",
    comment: "We should FaceTime!",
    date: "5/1/20",
  },
  {
    id: 5,
    type: "assignment",
    person: { name: "Rich", href: "#" },
    assigned: { name: "Anna", href: "#" },
    verb: "virtually dated",
    date: "5/1/20",
  },
  {
    id: 6,
    type: "assignment",
    person: { name: "Rich", href: "#" },
    assigned: { name: "Anna", href: "#" },
    verb: "finally met in person with",
    date: "7/12/20",
  },
  {
    id: 7,
    type: "assignment",
    person: { name: "Rich", href: "#" },
    assigned: { name: "Anna", href: "#" },
    verb: "decided he wanted to marry",
    date: "7/4/21",
  },
  {
    id: 8,
    type: "assignment",
    person: { name: "Rich", href: "#" },
    assigned: { name: "Anna", href: "#" },
    verb: "bought a ring in secret for",
    date: "9/8/21",
  },
  {
    id: 8,
    type: "comment",
    person: { name: "Rich", href: "#" },
    imageUrl: "couple/rich-headshot.png",
    comment: "Will you marry me? 😍",
    date: "10/10/21",
  },
  {
    id: 8,
    type: "comment",
    person: { name: "Anna", href: "#" },
    imageUrl: "couple/anna-headshot.jpg",
    comment: "Yes 😍",
    date: "10/10/21",
  },
  {
    id: 8,
    type: "assignment",
    person: { name: "Rich", href: "#" },
    assigned: { name: "Anna", href: "#" },
    verb: "is getting married to",
    date: "6/10/23",
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Timeline() {
  return (
    <>
      <h2 className="text-6xl font-bold font-dance tracking-tight sm:text-6xl text-white">Our Story</h2>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {activity.map((activityItem, activityItemIdx) => (
            <li key={activityItem.id}>
              <div className="relative pb-8">
                {activityItemIdx !== activity.length - 1 ? (
                  <span className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  {activityItem.type === "comment" ? (
                    <>
                      <div className="relative">
                        <img
                          className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-purple"
                          src={activityItem.imageUrl}
                          alt=""
                        />

                        <span className="absolute -bottom-0.5 -right-1 rounded-tl px-0.5 py-px">
                          <ChatAltIcon className="h-5 w-5 text-gray-200" aria-hidden="true" />
                        </span>
                      </div>
                      <div className="min-w-0 flex-1">
                        <div>
                          <div className="text-sm">
                            <a href={activityItem.person.href} className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </a>
                          </div>
                          <p className="mt-0.5 text-sm text-gray-300">{dayjs(activityItem.date).format("ll")}</p>
                        </div>
                        <div className="mt-2 text-sm text-white">
                          <p>{activityItem.comment}</p>
                        </div>
                      </div>
                    </>
                  ) : activityItem.type === "assignment" ? (
                    <>
                      <div>
                        <div className="relative px-1">
                          <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                            <UserCircleIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 py-1.5">
                        <div className="text-sm text-gray-500">
                          <a href={activityItem.person.href} className="font-medium text-gray-900">
                            {activityItem.person.name}
                          </a>{" "}
                          <span className="text-gray-300">{activityItem.verb} </span>
                          <a href={activityItem.assigned.href} className="font-medium text-gray-900">
                            {activityItem.assigned.name}
                          </a>{" "}
                          <span className="whitespace-nowrap text-gray-300">
                            {dayjs(activityItem.date).format("ll")}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : activityItem.type === "tags" ? (
                    <>
                      <div>
                        <div className="relative px-1">
                          <div className="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
                            <TagIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                      <div className="min-w-0 flex-1 py-0">
                        <div className="text-sm leading-8 text-gray-500">
                          <span className="mr-0.5">
                            <a href={activityItem.person.href} className="font-medium text-gray-900">
                              {activityItem.person.name}
                            </a>{" "}
                            <span className="text-gray-300">{activityItem.verb} </span>
                          </span>{" "}
                          <span className="mr-0.5">
                            {activityItem.tags.map((tag) => (
                              <Fragment key={tag.name}>
                                <span className="relative bg-white inline-flex items-center rounded-full border border-gray-300 px-3 py-0.5 text-sm">
                                  <span className="absolute flex-shrink-0 flex items-center justify-center">
                                    <span
                                      className={classNames(tag.color, "h-1.5 w-1.5 rounded-full")}
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span className="ml-3.5 font-medium text-gray-900">{tag.name}</span>
                                </span>{" "}
                              </Fragment>
                            ))}
                          </span>
                          <span className="whitespace-nowrap text-gray-300">
                            {dayjs(activityItem.date).format("ll")}
                          </span>
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </>
  )
}
