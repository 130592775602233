import React from "react";

const COLORS = ["fill-lilac", "fill-purple", "fill-yellow"];
const StyleInjector = ({ children, className = "" }) => {
  const classes = className.split(" ");
  const filteredClasses = classes.filter((c) => !c.startsWith("fill-"));
  const chosenColor = COLORS[Math.floor(Math.random() * COLORS.length)];
  const StyledChildren = () =>
    React.Children.map(children, (child) =>
      React.cloneElement(child, {
        className: `${filteredClasses.join(" ")} ${chosenColor}`,
      })
    );

  return <StyledChildren />;
};

export default StyleInjector;
