const SongSuggestions = () => {
  return (
    <div className=" flex justify-center">
      <div className="m-10 font-dance flex flex-col text-center w-3/4">
        <div className="font-semibold text-3xl">Anna & Rich</div>
        <div className=" m-1 text-1xl">June 10th, 2023</div>
      </div>
    </div>
  )
}

export default SongSuggestions
