import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import App from "./App"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom"

import {
  Home,
  OurStory,
  RSVP,
  TheHappyCouple,
  Venue,
  Accommodations,
  BridalParty,
  SongSuggestions,
  Contact,
  Registry,
  AccommodationOptions,
  AccommodationTravel,
  NoMatch,
  Movie,
  Children,
  Schedule,
} from "./components"
import ScrollReset from "./components/common/utilities/ScrollResetter"

const Authorization = ({ children }) => {
  const location = useLocation()

  if (false) {
    return <Navigate to="/" state={{ from: location }} replace />
  }

  return children
}

const withAuth = (Component) => <Authorization>{Component}</Authorization>

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ScrollReset />
      <Routes>
        <Route path="/" element={<App />}>
          <Route index element={<Home />} />
          <Route path="home" element={<Home />} />
          <Route path="story" element={<OurStory />} />
          <Route path="happy-couple" element={withAuth(<TheHappyCouple />)} />
          <Route path="registry" element={<Registry />} />
          <Route path="movie" element={<Movie />} />
          {/* <Route path="bridal-party" element={<BridalParty />} /> */}
          <Route path="venue" element={<Venue />} />
          <Route path="accommodations/*" element={<Accommodations />}>
            <Route index element={<AccommodationOptions />} />
            <Route path="travel" element={<AccommodationTravel />} />
          </Route>
          <Route path="music" element={<SongSuggestions />} />
          <Route path="rsvp" element={<RSVP />} />
          <Route path="children" element={<Children />} />
          <Route path="schedule" element={<Schedule />} />
          {/* <Route path="contact" element={<Contact />} /> */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
