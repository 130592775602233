import ReCAPTCHA from "react-google-recaptcha"
import { useState } from "react"
import { RadioGroup, Switch, Disclosure } from "@headlessui/react"
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon, ChevronDownIcon, CheckIcon } from "@heroicons/react/solid"
import { object, string, boolean, array } from "yup"
import { Formik, useFormikContext, ErrorMessage } from "formik"
import withContainer from "./common/utilities/withContainer"
import StyleInjector from "./common/utilities/ClassInjector"

import { ReactComponent as Divider } from "./assets/D2-38.svg"

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const steps = [
  { id: "01", name: "Read this FAQ", href: "#", status: "complete" },
  { id: "02", name: "Fill out the RSVP Card", href: "#", status: "current" },
  { id: "03", name: "Place in Mail", href: "#", status: "upcoming" },
]

const Dinner_Options = [
  {
    id: 1,
    title: "Grilled New York Strip Steak",
    description: "Garlic & Mushroom demi-glaze, with pesto mashed potatoes, grilled tomatoes, and asparagus.",
  },
  {
    id: 2,
    title: "Salmon",
    description: "Seared with tomatoes and lemon butter, warm lentil salad & asparagus.",
  },
  {
    id: 3,
    title: "Spinach Crêpes",
    description: "Stuffed spinach crêpe with eggplant, summer vegetables, ricotta, warm bean salad, and tomato coulis",
  },
  {
    id: 4,
    title: "Children",
    description: "Chicken Tenders, Mac & Cheese, Fruit Bowls or any combination of the three!",
  },
]

const faqs = [
  {
    question: "How do I RSVP?",
    answer:
      "You will have received an RSVP card in your invitation that you can fill out and send back in the included envelope!",
  },
  {
    question: "Can I bring a +1?",
    answer: "We are delighted to accomodate your significant other, however please check-in with us beforehand!",
  },
  {
    question: "Can I have more details about the menu?",
    answer: () => (
      <ul>
        {Dinner_Options.map((opt) => (
          <li className="mb-2" key={opt.id}>
            <div className="font-bold text-xl">{opt.title}</div>
            <div>{opt.description}</div>
          </li>
        ))}
      </ul>
    ),
  },
  {
    question: "How do I fill out the RSVP card?",
    answer: () => (
      <div className="grid grid-cols-3 gap-4">
        <span className="col-span-1 col-start-2 font-dance text-4xl">Like this!</span>
        <img className="col-span-3" src="rsvp-card.png"></img>
      </div>
    ),
  },
]

function Example() {
  return (
    <div>
      <div className="mx-auto max-w-7xl px-6 sm:py-8">
        <div className="mx-auto max-w-3xl divide-gray-200">
          <h2 className="text-center font-dance text-3xl tracking-tight text-gray-900 sm:text-4xl">
            Questions & Answers
          </h2>
          <dl className="mt-2 space-y-6 divide-y divide-gray-500">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt className="text-lg">
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-400">
                        <span className="font-medium text-gray-900">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          <ChevronDownIcon
                            className={classNames(
                              open ? "-rotate-180" : "rotate-0",
                              "h-6 w-6 transform text-green-dark"
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      {typeof faq.answer === "string" && <p className="text-base text-gray-700">{faq.answer}</p>}
                      {typeof faq.answer === "function" && <faq.answer />}
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

function RSVP() {
  return (
    <div className="bg-green-light overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 lg:grid lg:grid-cols-4 lg:gap-2">
          <div className="mt-5 lg:col-span-4">
            <span className="font-dance text-4xl">RSVP</span>
          </div>

          <div className="mt-5 lg:col-span-4">
            <div className="bg-green overflow-hidden sm:rounded-lg">
              <Example />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RSVP
