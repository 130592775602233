import { Outlet } from "react-router-dom"
import dayjs from "dayjs"

import relativeTime from "dayjs/plugin/relativeTime"
import localizedFormat from "dayjs/plugin/localizedFormat"

import Footer from "./components/common/Footer"
import NavigationHeader from "./components/common/NavigationHeader"
import ScheduleBanner from "./components/ScheduleBanner"

dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

export default function Example() {
  return (
    <>
      <NavigationHeader />
      <div className="main-content min-h-screen">
        <Outlet />
      </div>
      <ScheduleBanner />
      <Footer />
    </>
  )
}
