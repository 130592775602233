import { useState } from "react"
import { XIcon as XMarkIcon, ArrowSmRightIcon, CalendarIcon } from "@heroicons/react/outline"
import { Link, Outlet, useMatch, useLocation, useNavigate } from "react-router-dom"

export default function Example() {
  const [show, toggle] = useState(true)
  if (!show) return null
  return (
    <>
      {/*
          Make sure you add some bottom padding to pages that include a sticky banner like this to prevent
          your content from being obscured when the user scrolls to the bottom of the page.
        */}
      <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:flex sm:justify-center sm:px-6 sm:pb-5 lg:px-8">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-green-dark py-2.5 px-6 sm:rounded-xl sm:py-3 sm:pr-3.5 sm:pl-4">
          <p className="text-sm leading-6 text-white">
            <Link to="/schedule" key="announcements">
              <div className="flex flex-1 justify-center space-x-2">
                <CalendarIcon className="w-5 h-5" />
                <strong className="font-semibold">Day-of Schedule</strong>
                <span>|</span>
                <span>Click here to view!</span>
              </div>
            </Link>
          </p>
          <button type="button" className="-m-1.5 flex-none p-1.5" onClick={() => toggle((state) => !state)}>
            <span className="sr-only">Dismiss</span>
            <XMarkIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </button>
        </div>
      </div>
    </>
  )
}
