/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { useEffect, useRef } from "react"
import { CameraIcon, ExclamationIcon as ExclamationTriangleIcon } from "@heroicons/react/solid"
import "./common/google-maps.css"
import { Link } from "react-router-dom"
import StyleInjector from "./common/utilities/ClassInjector"
import { ReactComponent as Divider2 } from "./assets/D2-34.svg"

const faqs = [
  {
    id: 1,
    question: "1) What is the best gift you could get Anna & Rich?",
    answer:
      "Your presence! We want you here to celebrate with us and you should feel no pressure to get us anything at all (we promise).",
  },
  {
    id: 2,
    question: "2) What if I want to get you something?",
    answer:
      "One of the best gifts you could get us is something that means a lot to you. A gift that reminds you of us, time we spent together, or just something that will always bring us joy to look at, use and recall this joyous day.",
  },
  {
    id: 3,
    question: "3) What if I still want to get something I know you'll love?",
    answer:
      "We have put together a registry if you feel generous and would like to get us something. The registry is through Zola and is available below.",
  },
]

function Example() {
  return (
    <div className="">
      <div className="mx-auto max-w-7xl py-12 px-4 sm:py-12 sm:px-6 lg:px-8">
        <div className="max-w-2xl lg:mx-auto lg:text-center">
          <h2 className="text-3xl font-dance tracking-tight text-gray-900 sm:text-4xl">Our Registry</h2>
          <p className="mt-4 font-sans text-gray-700">
            We appreciate your kindness in looking to get us a gift. We know that to attend our wedding, many of you
            will spend your time and resources travelling to be with us. With that said, we would like to let you know a
            few things.
          </p>
        </div>
        <div className="mt-20">
          <dl className="space-y-10 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-10 lg:space-y-0">
            {faqs.map((faq) => (
              <div key={faq.id}>
                <dt className="font-semibold font-sans text-gray-900">{faq.question}</dt>
                <dd className="mt-3 font-sans text-gray-700">{faq.answer}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

export default function Registry() {
  useEffect(() => {
    const script = document.createElement("script")

    script.src = "zola.js"
    script.async = false

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  return (
    <div className="bg-green-light overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 lg:grid lg:grid-cols-4 lg:gap-8">
          <div className="mt-5 lg:col-span-4">
            <div className="bg-green overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <Example />
              </div>
            </div>
          </div>
        </div>

        <div className="mt-4 lg:grid lg:grid-cols-1 lg:gap-8">
          <div className="bg-green overflow-hidden sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <div className="mt-3">
                <a
                  class="zola-registry-embed"
                  href="www.zola.com/wedding/iloveyoumostardently"
                  data-registry-key="iloveyoumostardently"
                >
                  Our Zola Wedding Registry
                </a>
              </div>

              {/* <div className="font-semibold font-sans text-gray-900 mt-6">Registry coming soon!</div> */}

              <div className="font-semibold font-sans text-gray-900 mt-6">
                Embed not working?{" "}
                <a
                  className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                  href="https://www.zola.com/wedding/iloveyoumostardently/registry"
                >
                  Click here to visit the registry on Zola.com
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
