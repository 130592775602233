/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/typography'),
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import { CameraIcon } from "@heroicons/react/solid";
import "./common/google-maps.css";
import { Link } from "react-router-dom";
import StyleInjector from "./common/utilities/ClassInjector";
import { ReactComponent as Divider2 } from "./assets/D2-34.svg";

export default function Venue() {
  return (
    <div className="bg-green-light overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 lg:grid lg:grid-cols-4 lg:gap-8">
          <div className="lg:col-span-2">
            <h2 className="mt-10 text-5xl leading-8 tracking-tight text-gray-900 font-dance">
              The Inns of Aurora
            </h2>
            <div className="mt-3">
              <a
                className="text-gray-900 text-2xl underline prose"
                href="https://innsofaurora.com/"
                target="_blank"
              >
                https://innsofaurora.com/
              </a>
            </div>

            <p className="prose text-gray-500 mt-5 italic">
              The ceremony and reception will take place at the Aurora Inn at
              391 Main St, Aurora, NY.
            </p>

            <p className="prose text-gray-500 mt-3">
              With rolling hills, panoramic vistas, and plunging waterfalls, the
              pristine lakes that make up the Finger Lakes region of upstate New
              York were formed by glaciers over two million years ago.
            </p>

            <p className="prose text-gray-500 mt-3">
              Nestled on the calm shores of Cayuga Lake—the largest of the
              Finger Lakes—the storybook village of Aurora is dotted with
              historic estates that have been restored into charming,
              comfortable inns. Guests are welcome to reserve a room in one of
              the Inn’s unique and charming properties, or find accommodation in
              nearby Ithaca.
            </p>

            <p className="flex justify-start">
              <Link
                role="button"
                to="/accommodations"
                className="mt-4 mb-4 inline-flex items-center px-5 py-3 border border-transparent shadow-sm text-md leading-4 font-medium rounded-md text-white bg-green hover:bg-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:green-dark"
              >
                View Accomodation Options
              </Link>
            </p>
          </div>

          <div className="mt-5 lg:col-span-2">
            <figure>
              <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                <img
                  className="rounded-lg shadow-lg object-cover object-center"
                  src="/aurora-inn.jpg"
                  alt="Whitney leaning against a railing on a downtown street"
                  width={1184}
                  height={1376}
                />
              </div>
            </figure>
          </div>
        </div>

        <div className="mt-4 lg:grid lg:grid-cols-2 lg:gap-8">
          <div className="mt-3">
            <figure>
              <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                <img
                  className="rounded-lg shadow-lg object-cover object-center"
                  src="/ceremony-sunset.jpg"
                  alt="Whitney leaning against a railing on a downtown street"
                  width={1184}
                  height={1376}
                />
              </div>
            </figure>
          </div>

          <div className="mt-3">
            <figure>
              <div className="aspect-w-12 aspect-h-7 lg:aspect-none">
                <img
                  className="rounded-lg shadow-lg object-cover object-center"
                  src="/morgan-house.jpg"
                  alt="Whitney leaning against a railing on a downtown street"
                  width={1184}
                  height={1376}
                />
              </div>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}
