const Children = () => {
  return (
    <div className="bg-green-light overflow-hidden">
      <div className="relative max-w-7xl mx-auto py-8 px-4 sm:px-6 lg:px-8">
        <div className="mt-8 lg:grid lg:grid-cols-4 lg:gap-2">
          <div className="mt-5 lg:col-span-4">
            <span className="font-dance text-4xl">Children</span>
          </div>

          <div className="text-lg lg:col-span-4">
            <p className="mt-5">
              Anna and Rich are delighted to have you come to our wedding, and we realize that those with young children
              should not have to make the choice between leaving their loved ones at home and joining us for this
              occasion.
            </p>

            <p className="mt-5">
              However, Rich is affected by{" "}
              <a
                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                href="https://www.health.harvard.edu/blog/misophonia-sounds-really-make-crazy-2017042111534"
                target={"_blank"}
              >
                Misophonia, which can induce anxiety, and a flight-response in the brain
              </a>{" "}
              from certain triggering noises.
            </p>

            <p className="mt-5">
              With that said, we have a request for guests that will be bringing children who are too young/unable to
              recognize that crying/noise during the ceremony could make the groom uncomfortable.
            </p>

            <p className="mt-5">
              We have arranged that during the ceremony only, a{" "}
              <span className="underline">New York state-certified childcare professional</span> will available to
              care/watch infants and toddlers in a room on the <span className="italic">Inns of Aurora</span> property.{" "}
              <span className="font-bold">This would be for the ceremony only (not the reception)</span>. Our ceremony
              is only planned to be 20-30 minutes: there is no Mass or religious service.
            </p>

            <p className="mt-5">Once the ceremony is over, we would love to meet, see and celebrate with everyone!</p>

            <p className="mt-5">
              We hope to have addressed any concerns you might have, but if you still have questions or are
              uncomfortable with this suggestion, <span className="font-bold">please reach out to us.</span> We want to
              work with you to ensure you can attend with your family.
            </p>
          </div>

          <div className="mt-5 mb-4 lg:col-span-4">
            <span className="font-dance text-4xl">Gratitude (Sanctuary Building)</span>
            <p className="mt-5 text-lg">
              This is the location we are thinking about for young children. This sanctuary building is within 200 feet
              of the ceremony and a 2 minute walk to the ceremony, cocktail hour and reception.
            </p>
          </div>

          <div className="lg:col-span-4">
            <div className="grid grid-cols-2 gap-5">
              <div className="col-span-1 aspect-h-2 rounded-lg bg-green overflow-hidden">
                <div className="aspect-w-3 m-4">
                  <img src="gratitude.png"></img>
                </div>
              </div>
              <div className="col-span-1 aspect-h-2 rounded-lg bg-green overflow-hidden">
                <div className="aspect-w-3 m-4">
                  <img src="gratitude-inside@0.5x.png"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Children
