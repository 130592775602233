import { Fragment } from "react"
import { Popover, Transition } from "@headlessui/react"

import { ReactComponent as SunflowerIcon } from "../assets/30.svg"
import StyleInjector from "./utilities/ClassInjector"

import {
  HomeIcon,
  MenuIcon,
  PhoneIcon,
  XIcon,
  GlobeIcon,
  ClipboardListIcon,
  MusicNoteIcon,
  ShoppingBagIcon,
  EmojiHappyIcon,
  ColorSwatchIcon,
} from "@heroicons/react/outline"

import { ChevronDownIcon } from "@heroicons/react/solid"

import { Link } from "react-router-dom"

import { ReactComponent as Divider } from "../assets/D2-34.svg"

const ADDITIONAL_PAGES = [
  {
    name: "Venue",
    href: "venue",
    description: (
      <span>
        Learn about <span className="italic">The Inns of Aurora</span>, nestled among the Finger Lakes of New York.
      </span>
    ),
    icon: HomeIcon,
  },
  {
    name: "Accommodations",
    href: "accommodations",
    description: "Find information about different accommodation options.",
    icon: GlobeIcon,
  },
  {
    name: "Children",
    href: "children",
    description: "We don't have any, but you might! Here's the details.",
    icon: EmojiHappyIcon,
  },
  {
    name: "Registry",
    href: "registry",
    description: "Your presence is a gift enough, however we do have some ideas.",
    icon: ShoppingBagIcon,
  },
  {
    name: "RSVP",
    href: "rsvp",
    description: "Questions? Want to know the menu details? That and more, right here!",
    icon: ShoppingBagIcon,
  },
]

const CALLS_TO_ACTION = []

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const NavigationHeader = () => (
  <div className="relative">
    <Popover className="relative bg-green shadow">
      <div className="max-w-7xl mx-auto px-4 sm:px-6">
        <div className="flex justify-between items-center py-6 md:justify-start md:space-x-10">
          <div className="flex justify-start lg:w-0 lg:flex-1">
            <h2 className="font-1xl text-1xl">
              <StyleInjector className="fill-lilac w-12">
                <SunflowerIcon />
              </StyleInjector>
            </h2>
          </div>

          <Link to="home" className="text-base font-medium text-green-dark hover:text-green-dark">
            Home
          </Link>

          <Link to="happy-couple" className="text-base font-medium text-green-dark hover:text-green-dark">
            The Happy Couple
          </Link>

          <div className="-mr-2 -my-2 md:hidden">
            <Popover.Button className="bg-green-dark rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
              <span className="sr-only">Open menu</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </Popover.Button>
          </div>

          <Popover.Group as="nav" className="hidden md:flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-gray-500" : "text-green-dark",
                      "group bg-green rounded-md inline-flex items-center text-base font-medium hover:text-green-dark focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                    )}
                  >
                    <span>Additional Details</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-green-dark",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -ml-4 mt-3 transform z-10 px-2 w-screen max-w-md sm:px-0 lg:ml-0 lg:left-1/2 lg:-translate-x-1/2">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-green-light px-5 py-6 sm:gap-8 sm:p-8">
                          {ADDITIONAL_PAGES.map((item, idx) => (
                            <>
                              <Popover.Button
                                as={Link}
                                key={item.name}
                                to={item.href}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-gray-50"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-green-600" aria-hidden="true" />
                                <div className="ml-4">
                                  <p className="text-base font-medium text-green-dark">{item.name}</p>
                                  <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                                </div>
                              </Popover.Button>
                              {/* {idx < ADDITIONAL_PAGES.length - 1 && (
                                <div className="flex justify-center">
                                  <StyleInjector className="w-1/2 fill-purple">
                                    <Divider />
                                  </StyleInjector>
                                </div>
                              )} */}
                            </>
                          ))}
                        </div>
                        <div className="px-5 py-5 bg-green-dark space-y-6 sm:flex sm:space-y-0 sm:space-x-10 sm:px-8">
                          {CALLS_TO_ACTION.map((item) => (
                            <div key={item.name} className="flow-root">
                              <Link
                                to={item.href}
                                className="-m-3 p-3 flex items-center rounded-md text-base font-medium text-white hover:bg-green-light"
                              >
                                <item.icon className="flex-shrink-0 h-6 w-6 text-gray-400" aria-hidden="true" />
                                <span className="ml-3">{item.name}</span>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </Popover.Group>
        </div>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-green divide-y-2 divide-gray-50 border-2 border-green-dark">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>Additional Details</div>
                <div className="-mr-2">
                  <Popover.Button className="bg-green-dark rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-green-500">
                    <span className="sr-only">Close menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-y-8">
                  {ADDITIONAL_PAGES.map((item, idx) => (
                    <>
                      <Popover.Button
                        as={Link}
                        key={item.name}
                        to={item.href}
                        className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50"
                      >
                        <item.icon className="flex-shrink-0 h-6 w-6 text-green-600" aria-hidden="true" />
                        <div className="ml-4">
                          <p className="text-base font-medium text-green-dark">{item.name}</p>
                          <p className="mt-1 text-sm text-gray-500">{item.description}</p>
                        </div>
                      </Popover.Button>
                      {/* {idx < ADDITIONAL_PAGES.length - 1 && (
                        <div className="flex justify-center">
                          <StyleInjector className="w-1/2 fill-purple">
                            <Divider />
                          </StyleInjector>
                        </div>
                      )} */}
                    </>
                  ))}
                </nav>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  </div>
)

export default NavigationHeader
