import React from "react"
import Countdown from "react-countdown"
import { Link } from "react-router-dom"
import ClassInjector from "./common/utilities/ClassInjector"
import { ReactComponent as HorizontalFlowerDivider } from "./assets/D2-19.svg"

const WEDDING_DATE = Date.parse("10 Jun 2023 16:30:00 EST")

const Home = () => (
  <main className="lg:relative bg-green-light">
    <div className="relative w-full h-64 sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
      <img className="absolute inset-0 w-full h-full object-cover" src="couple/ARSNEAKS-3.jpg" alt="" />
    </div>
    <div className="mx-auto max-w-7xl w-full pt-16 pb-20 text-center lg:py-48 lg:text-center">
      <div className="px-4 lg:w-1/2 sm:px-8 xl:pr-16">
        <h1 className="text-4xl tracking-tight font-bold text-gray-500 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
          <span className="block xl:inline">Anna & Rich are</span>{" "}
          <span className="block text-green-dark xl:inline">getting married!</span>
        </h1>

        <p style={{ marginTop: "1rem" }}>
          <h2 className="text-3xl tracking-tight text-green-dark sm:text-2xl md:text-6xl lg:text-3xl xl:text-3xl font-dance">
            <Countdown
              date={WEDDING_DATE}
              renderer={({ days, hours, minutes, seconds }) => (
                <span>
                  {days} days, {hours} hours & {minutes} minutes
                </span>
              )}
            />
          </h2>
        </p>
        <p className="mt-3 max-w-md mx-auto text-lg text-gray-500 sm:text-xl md:mt-5 md:max-w-3xl">
          We invite you to come celebrate the wedding of Anna Shuster and Rich Dillon in beautiful Aurora, NY
        </p>

        <div className="mt-3">
          <ClassInjector className="fill-purple">
            <HorizontalFlowerDivider />
          </ClassInjector>
        </div>

        <div className="mt-10 sm:flex sm:justify-center lg:justify-center">
          <div className="rounded-md shadow">
            <Link
              role="button"
              to="/movie"
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-green hover:bg-green-700 md:py-4 md:text-lg md:px-10"
            >
              I Love You Most Ardently!
            </Link>
          </div>
        </div>
      </div>
    </div>
  </main>
)

export default Home
